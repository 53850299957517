<template>
  <div class="app-container v">
    <div class="head-container">
      <tree-picker v-model="query.deptId" clearable url="api/dept" placeholder="选择部门" class="filter-item" @change="toQuery" style="width: 180px;" />
      <el-select v-model="query.enabled" filterable clearable placeholder="状态" class="filter-item" style="width: 90px" @change="toQuery">
        <el-option :value="true" label="激活" />
        <el-option :value="false" label="禁用" />
      </el-select>
      <el-input v-model="query.blurry" clearable placeholder="输入名称或者账号搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-input v-model="query.roleName" clearable placeholder="输入角色搜索" style="width: 150px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <div v-permission="['ADMIN','USER_ALL','USER_CREATE']" style="display: inline-block;margin: 0px 2px;">        
        <el-tooltip class="item" effect="dark" content="新增用于登录DMS系统的账号" placement="top">   
          <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="add">新增</el-button>   
        </el-tooltip>
      </div>
      <div style="display: inline-block;">
        <el-button v-permission="['ADMIN','USER_ALL','USER_SELECT']" :loading="downloadLoading" size="mini" class="filter-item" type="warning" icon="el-icon-download" @click="download">导出</el-button>
      </div>
    </div>

    <el-table v-loading="loading" :data="data" size="small" height="200px">
      <el-table-column prop="username" label="账号" />
      <el-table-column prop="userRealName" label="姓名" />
      <el-table-column prop="phone" label="电话" />
      <!-- <el-table-column :show-overflow-tooltip="true" prop="email" label="邮箱" /> -->
      <el-table-column label="部门">
        <template slot-scope="scope">{{ scope.row.dept ? scope.row.dept.name:""}}</template>
      </el-table-column>
      <el-table-column label="角色" :formatter="r=>{return showRoles(r.roles)}" />
      <el-table-column :show-overflow-tooltip="true" prop="createTime" label="创建日期">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.enabled">激活</el-tag>
          <el-tag type="info" v-else>禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column v-if="checkPermission(['ADMIN','USER_ALL','USER_EDIT','USER_DELETE'])" width="160" align="center" fixed="right">
        <div slot-scope="scope" class="row-commands">
          <template v-if="scope.row.createEnterpriseId === user.entId">
            <el-button v-permission="['ADMIN','USER_ALL','USER_EDIT']" size="mini" type="text" @click="edit(scope.row)">编辑</el-button>
            <el-button v-permission="['ADMIN','USER_ALL','USER_EDIT']" size="mini" type="text" @click="resetPwd(scope.row)" v-if="scope.row.enabled">重置密码</el-button>
            <el-popover v-permission="['ADMIN','USER_ALL','USER_DELETE']" :ref="scope.row.id" placement="top" width="180">
              <p>确定删除本条数据吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
                <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
              </div>
              <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
            </el-popover>
          </template>
          <template v-else>
            <el-button size="mini" type="text" @click="view(scope.row)">查看</el-button>
          </template>
        </div>
      </el-table-column>
    </el-table>

    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />

    <eForm ref="form" :is-add="isAdd" self />
    <reset-pwd ref="pwd" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { del, downloadUser } from "@/api/user";
import { getDepts } from "@/api/dept";
import { parseTime, downloadFile } from "@/utils/index";
import eForm from "./form";
import resetPwd from "./reset-pwd";
import { mapGetters } from "vuex";
import { download } from "@/api/data";

export default {
  name: "User",
  components: { eForm, resetPwd },
  mixins: [initData],
  data() {
    return {
      isAdd: false,
      delLoading: false,
      downloadLoading: false,
      query: {
        deptId: null,
        enabled: null,
        blurry: null,
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  created() {
    this.init();
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = "api/users";
      this.params = {
        sort: "id,desc",
        deptId: this.query.deptId,
        blurry: this.query.blurry,
        enabled: this.query.enabled,
        roleName:this.query.roleName
      };
      return true;
    },
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          console.log(err.response.data.message);
        });
    },
    handleNodeClick(data) {
      // if (data.pid === 0) {
      //   this.deptId = null;
      // } else {
      //   this.deptId = data.id;
      // }
      this.deptId = data.id;
      this.init();
    },
    add() {
      this.isAdd = true;
      this.$refs.form.resetForm(null,this.user.entId);
    },
    // 导出
    download() {
      // this.downloadLoading = true;
      // downloadUser()
      //   .then(result => {
      //     downloadFile(result, "用户列表", "xlsx");
      //     this.downloadLoading = false;
      //   })
      //   .catch(() => {
      //     this.downloadLoading = false;
      //   });
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/users/download", this.params)
        .then((result) => {
          downloadFile(result, "用户列表", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
    // 数据转换
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          if (j === "createTime" || j === "lastPasswordResetTime") {
            return parseTime(v[j]);
          } else if (j === "enabled") {
            return parseTime(v[j]) ? "启用" : "禁用";
          } else {
            return v[j];
          }
        })
      );
    },
    view(data) {
      this.$refs.form && this.$refs.form.showDetail(data);
    },
    edit(data) {
      this.isAdd = false;
      let fd = JSON.parse(JSON.stringify(data));
      fd._roleIds = data.roles.map((r) => {
        return r.id;
      });
      this.$refs.form && this.$refs.form.resetForm(fd);
    },
    resetPwd(data) {
      this.$refs.pwd && this.$refs.pwd.resetForm(data.username);
    },
    showRoles(data){
      let n="";
      if(data.length>1){
        data.forEach(o => {
          n+=o.name+",";
        });
      }else if(data.length==1){
        data.forEach(o => {
          n=o.name;
        });
      }
      return n;
    },
  }
};
</script>